import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import css from './companyButton.module.scss';
import {useTranslation} from 'react-i18next';
import LogoWhite from "../../../images/logos/deeplai-company-logo-white.svg";
import LogoBLack from "../../../images/logos/deeplai-company-logo.svg";

const CompanyButton = props => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
            author
            siteUrl
            companyName
            companyUrl
            socialLinks {
              facebook
              twitter
              instagram
              linkedin
              youtube
              pinterest
            }
          }
        }
      }
    `
  );

  const { t } = useTranslation();

  const {
    height = '24px',
    version = 'white'
  } = props;

  const companyUrl = site.siteMetadata.companyUrl;

  return (
    <a href={companyUrl} target="_blank" className={css.link}>
      { version === 'white' && <img src={LogoWhite} alt={t('shared.imageAlts.company')} style={{ height }} />}
      { version === 'black' && <img src={LogoBLack} alt={t('shared.imageAlts.company')} style={{ height }} />}
    </a>
  )
};

export default CompanyButton;
